<template>
  <div>
    <create
      @closePopup="createDialog = false"
      @createData="create($event)"
      @getShcools="getSchoolIems($event)"
      :schoolItems="schoolItems"
      :dialog="createDialog"
      :loader="createLoader"
    />
    <edit
      @closePopup="editDialog = false"
      @editData="edit($event)"
      @getShcools="getSchoolIems($event)"
      :schoolItems="schoolItems"
      :dialog="editDialog"
      :dialogData="dialogData"
      :loader="showLoader"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteItem($event)"
      :dialog="deleteDialog"
      :item="deleteDialogData"
      :loader="deleteLoader"
    />
    <v-row class="mb-4">
      <v-col class="d-flex justify-end"
        ><v-btn color="primary" @click="createAble"
          ><span style="color: white;">{{ $t("add") }}</span></v-btn
        ></v-col
      >
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :data="tableData.data"
      :editable="editAble"
      :deleteable="deleteAble"
      :loader="loader"
      :meta="pagination"
    />
  </div>
</template>
<script>
import { Grade } from "@/store/common/grade";
import { Educational } from "@/store/common/educational_level";
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import create from "./create.vue";
import edit from "./edit.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";
export default {
  components: {
    dataTable,
    create,
    edit,
    deletePopup,
  },
  data() {
    return {
      Grade,
      Educational,
      createDialog: false,
      editDialog: false,
      dialogData: null,
      deleteDialog: false,
      deleteDialogData: null,
      schoolItems: [],
    };
  },
  methods: {
    getData(page) {
      let params = {
        page: page,
      };
      Grade.get(params);
      this.dialog = false;
    },
    async getSchoolIems(params) {
      await Educational.get(params);
      this.schoolItems = this.schoolItems.concat(this.shcoolData);
    },

    //create
    createAble() {
      this.createDialog = true;
    },
    async create(data) {
      await Grade.post(data);
      this.createDialog = false;
    },

    //edit
    async editAble(obj) {
      this.editDialog = true;
      await Grade.show(obj.id);
      this.dialogData = Grade.tableData.showData;
    },
    async edit(data) {
      await Grade.edit(data.data, data.id);
      this.editDialog = false;
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteItem(id) {
      await Grade.deleteItem(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    //data
    tableData() {
      return Grade.tableData;
    },
    shcoolData() {
      return Educational.tableData.data;
    },
    pagination() {
      return Grade.tableData.meta;
    },
    //loaders
    loader() {
      return Grade.getState.loading;
    },
    createLoader() {
      return Grade.postState.loading;
    },
    editLoader() {
      return Grade.editState.loading;
    },
    showLoader() {
      return Grade.showState.loading;
    },
    deleteLoader() {
      return Grade.deleteState.loading;
    },
  },
  mounted() {
    this.getData(1);
  },
};
</script>
